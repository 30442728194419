import React, { useState } from 'react';
import MaskedInput from 'antd-mask-input';

import FooterInfos from '@src/components/Shared/FooterInfos';
import FooterSociallis from '@src/components/Shared/FotterSociallis';
import AgradecimentoRecorrente from '@src/pages/AgradecimentoRecorrente';
import AgradecimentoUnica from '@src/pages/AgradecimentoUnica';

import { PayPalButton } from 'react-paypal-button';


import Button from '@components/Shared/Buttons/Button';
import { ReportGmailerrorred } from '@mui/icons-material/';
import { Grid } from '@material-ui/core';
import { Form, Input, Spin, Radio, Select as SelectAntd, Checkbox } from 'antd';


import ButtonComponent from '@components/Shared/Buttons/Button';
import InvisibleContent from '@components/Shared/InvisibleContent';
import '@styles/AntStyles.css';
import { addDays } from 'date-fns';

import Cartao from '@assets/Login/cartao.png';
import Energia from '@assets/Login/lampada-de-iluminacao.png';
import Boleto from '@assets/Login/codigo-de-barras.png'
import Pix from '@assets/Login/codigo-qr.png';
import Debito from '@assets/Login/transferencia-de-dinheiro.png';

import LogoCampanha from '@assets/Login/facasepresente-osid.png';
import Proximo from '@assets/Login/proximo.png';

import SocioProtetor from '@assets/Login/50mil-sejaumsocioprotetor.png'

import Service, {
  IRequestCreatePessoa,
  IRequestCreatePessoaCredito,
  IRequestCreatePessoaDebito,
  IRequestCreatePessoaEnergia,
  IRequestPessoa,
} from './services';

import { MaskCPFeCNPJ } from '@utils/Masks';
import { validaCPFandCNPJ } from '@utils/Validators';
import SearchCEPCorreios from '@utils/SearchCEPCorreios';
import { isReal, ClearString } from '@utils/Masks';

import useDebounce from '@hooks/useDebounce';
import { type } from 'os';
import { clear } from 'console';

const DATA = addDays(new Date(), 0).toISOString().split('T')[0];

interface IConvenio {
  id: number;
  descricao: string;
}

interface IOperacaoCaixa {
  id: number;
  descricao: string;
  codigo_operacao: string;
}

interface IOperacaoSantander {
  id: number;
  descricao: string;
  codigo_operacao: string;
}

interface IRecorrencia {
  id: number;
  descricao: string;
}

interface IData {
  id: string;
}


const Principal: React.FC = () => {

  const [formPessoaRef] = Form.useForm();
  const [formRef] = Form.useForm();

  const [loadingCreatePessoaCredito, setLoadingCreatePessoaCredito] = useState(false);
  const [loadingCreatePessoaPix, setLoadingCreatePessoaPix] = useState(false);
  const [loadingCreatePessoaBoleto, setLoadingCreatePessoaBoleto] = useState(false);
  const [loadingCreatePessoaDebito, setLoadingCreatePessoaDebito] = useState(false);
  const [loadingCreatePessoaEnergia, setLoadingCreatePessoaEnergia] = useState(false);
  const [loadingPessoa, setLoadingPessoa] = useState(false);


  const [loading, setLoading] = useState(false);
  const [valorDoacao, setValorDoacao] = useState(false);

  const [formaContribPix, setFormaContribPix] = useState(false);
  const [formaContribCredito, setFormaContribCredito] = useState(false);
  const [formaContribBoleto, setFormaContribBoleto] = useState(false);
  const [formaContribDebito, setFormaContribDebito] = useState(false);
  const [formaContribEnergia, setFormaContribEnergia] = useState(false);

  const [fraseDoacao0, setFraseDoacao0] = useState(false);
  const [fraseDoacao1, setFraseDoacao1] = useState(false);
  const [fraseDoacao2, setFraseDoacao2] = useState(false);
  const [fraseDoacao3, setFraseDoacao3] = useState(false);
  const [fraseDoacao4, setFraseDoacao4] = useState(false);
  const [fraseDoacao5, setFraseDoacao5] = useState(false);
  const [fraseDoacao6, setFraseDoacao6] = useState(false);
  const [fraseDoacao7, setFraseDoacao7] = useState(false);
  const [fraseDoacao8, setFraseDoacao8] = useState(false);
  const [fraseDoacao9, setFraseDoacao9] = useState(false);
  const [fraseDoacao10, setFraseDoacao10] = useState(false);
  const [fraseDoacao11, setFraseDoacao11] = useState(false);
  const [fraseDoacao12, setFraseDoacao12] = useState(false);
  const [fraseDoacao13, setFraseDoacao13] = useState(false);
  const [fraseDoacao14, setFraseDoacao14] = useState(false);
  const [fraseDoacao15, setFraseDoacao15] = useState(false);
  const [fraseDoacao16, setFraseDoacao16] = useState(false);
  const [fraseDoacao17, setFraseDoacao17] = useState(false);
  const [fraseDoacao18, setFraseDoacao18] = useState(false);
  const [fraseDoacao19, setFraseDoacao19] = useState(false);
  const [fraseDoacao20, setFraseDoacao20] = useState(false);
  const [fraseDoacao21, setFraseDoacao21] = useState(false);
  const [fraseDoacao22, setFraseDoacao22] = useState(false);
  const [fraseDoacao23, setFraseDoacao23] = useState(false);
  const [fraseDoacao24, setFraseDoacao24] = useState(false);
  const [fraseDoacao25, setFraseDoacao25] = useState(false);
  const [fraseDoacao26, setFraseDoacao26] = useState(false);
  const [fraseDoacao27, setFraseDoacao27] = useState(false);
  const [fraseDoacao28, setFraseDoacao28] = useState(false);
  const [fraseDoacao29, setFraseDoacao29] = useState(false);

  const [confDadosPessoais, setConfDadosPessoais] = useState(false);
  const [confValorDoacao, setConfValorDoacao] = useState(false);
  const [confFormaContrib, setConfFormaContrib] = useState(false);
  const [doacaoRecorrente, setDoacaoRecorrente] = useState(false);
  const [doacaoPaypal, setDoacaoPaypal] = useState(false);

  const [tipoDocumento, setTipoDocumento] = useState<'pf' | 'pj'>('pf');

  const [valorModifyValorDebito, setValorModifyValorDebito] =
  useState<string>('');

  const [valorPaypal1, setValorPaypal1] =useState<number>(0);
  const [valorPaypal2, setValorPaypal2] =useState<number>(0);
  const [valorPaypal3, setValorPaypal3] =useState<number>(0);
  const [valorPaypal4, setValorPaypal4] =useState<number>(0);
  const [valorPaypal5, setValorPaypal5] =useState<number>(0);


  const [openModalTermo, setOpenModalTermo] =
  useState(false);


  const [loadingCEP, setLoadingCEP] = useState(false);
  const [uf, setUF] = useState<any>();
  const [checkedTermo1, setCheckedTermo1] = useState(false);
  const [checkedTermo2, setCheckedTermo2] = useState(false);

  const [convenioSelecionada, setConvenioSelecionada] =
  useState('');

  const [convenio, setConvenio] = useState<IConvenio[]>([
    { id: 5, descricao: 'BANCO DO BRASIL' },
    { id: 7, descricao: 'BANCO SANTANDER S.A.' },
    { id: 9, descricao: 'BANCO BRADESCO S.A.' },
    { id: 22, descricao: 'CAIXA ECONOMICA FEDERAL' },
    { id: 23, descricao: 'BANCO ITAÚ S.A.' },
  ]);


  const [operacaoCaixa, setOperacaoCaixa] = useState<IOperacaoCaixa[]>([
    { id: 1, descricao: 'Cta. Corrente - P.Física', codigo_operacao: '001' },
    { id: 2, descricao: 'Cta. Simples - P.Física', codigo_operacao: '002' },
    { id: 3, descricao: 'Cta. Corrente - P.Jurídica', codigo_operacao: '003' },
    { id: 4, descricao: 'Entidades Públicas', codigo_operacao: '006' },
    { id: 5, descricao: 'Dep. Inst. Financeiras', codigo_operacao: '007' },
    { id: 6, descricao: 'Consignação em pagamento', codigo_operacao: '011' },
    { id: 7, descricao: 'Poupança - P.Física', codigo_operacao: '013' },
    { id: 8, descricao: 'Conta Benefício', codigo_operacao: '018' },
    { id: 9, descricao: 'Poupança - P.Jurídica', codigo_operacao: '022' },
    { id: 10, descricao: 'Conta Eletrônica', codigo_operacao: '023' },
    { id: 11, descricao: 'Poupança-Créd Imobiliário', codigo_operacao: '028' },
    { id: 12, descricao: 'Dep. Lotéricos', codigo_operacao: '043' },
  ]);

  const [operacaoSantander, setOperacaoSantamder] = useState<IOperacaoSantander[]>([
    { id: 13, descricao: 'Operação 001', codigo_operacao: '001' },
    { id: 14, descricao: 'Operação 002', codigo_operacao: '002' },
    { id: 15, descricao: 'Operação 003', codigo_operacao: '003' },
    { id: 16, descricao: 'Operação 005', codigo_operacao: '005' },
    { id: 17, descricao: 'Conta salário', codigo_operacao: '060' },
    { id: 18, descricao: 'Poupança', codigo_operacao: '071' },
    { id: 19, descricao: 'Cta. Corrente - P.Física', codigo_operacao: '092' },
  ]);

  const [recorrencias, setRecorrencias] = useState<IRecorrencia[]>([
    { id: 1, descricao: 'Mensal' },
    { id: 2, descricao: 'Bimestral' },
    { id: 3, descricao: 'Trimestral' },
    { id: 6, descricao: 'Semestral' },
    { id: 12, descricao: 'Anual' },
  ]);

  const [data, setDatas] = useState<IData[]>([
    { id: '1' },  { id: '2' }, { id: '3' },{ id: '4' },{id: '5' },
    {id: '6' }, {id: '7' }, {id: '8' }, {id: '9' }, {id: '10' },
    {id: '11' }, {id: '12' }, {id: '13' }, {id: '14' }, {id: '15' },
    {id: '16' }, {id: '17' }, {id: '18' }, {id: '19' }, {id: '20' },
    {id: '21' }, {id: '22' }, {id: '23' }, {id: '24' }, {id: '25' },
    {id: '26' }, {id: '27' }, {id: '28' }, {id: '29' }, {id: '29' },
    {id: '30' }, {id: '31' },
  ]);

  const [etapa, setEtapa] = useState(0);

  const filterCheckTipoConvenio = (descricao: any) => {
    const forma = convenio.filter(x => x.descricao === descricao);

    if (forma.length > 0) setConvenioSelecionada(forma[0].descricao);
  };


  const HandleChangeEtapa = (value: number) => {
    setEtapa(value);

  };

  function handleInput(e: any) {
    var ss = e.target.selectionStart;
    var se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  };





  async function onSubmitPessoa(values: any) {
    setLoadingPessoa(true);
    setLoading(true);
    //console.log(values);

    const services = new Service();
    const createPessoa: IRequestPessoa = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
        indicador: values.indicador,
        leu_comunicado_privacidade: checkedTermo2,
        concede_compartilhamento_dados: checkedTermo1,
      },
      doacao: {
        valor: parseInt(valorModifyValorDebito),

        cartao: values.cartao === undefined
        ?false
        :true,
        boleto: values.boleto === false,
        pix: values.pix  === false,
        recorrente: values.recorrente  === undefined
          ?false
          :true,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: values.cep,
        uf: values.uf,
      },
      entidade: {
        nome: 'osid'
      },
      campanha:{
        descricao: 'teste',
      },
      cartao: {
        number: values.number,
        verification_value: values.verification_value,
        first_name: values.first_name,
        last_name: values.last_name,
        month: values.month,
        year: values.year,
      },
      debito_automatico: {
        convenio: values.convenio,
        vencimento: values.vencimento,
        numero_agencia: values.numero_agencia,
        digito_agencia: values.digito_agencia,
        numero_conta: values.numero_conta,
        digito_conta: values.digito_conta,
        operacao: values.operacao,
        valor: parseInt(valorModifyValorDebito),
      },
      debito_coelba: {
        numero_conta_energia: values.numero_conta_energia,
        recorrencia: values.recorrencia,
        valor: parseInt(valorModifyValorDebito),
      }
    };
    setLoadingPessoa(false);
  }

  {/* FUNCTION CREATE DOAÇÃO */}

  async function onSubmitCredito(values: any) {

    setLoadingCreatePessoaCredito(true);
    setLoading(true);
    //console.log(values);

    const services = new Service();

    function limparValor(valor: string): string {
      return valor.replace(/[^\d]+/g, '');
    }
    const cepLimpo = limparValor(values.cep);

    const createPessoaCredito: IRequestCreatePessoaCredito = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
        indicador: values.indicador,
        leu_comunicado_privacidade: checkedTermo2,
        concede_compartilhamento_dados: checkedTermo1,
      },
      doacao: {
        valor: parseInt(valorModifyValorDebito),

        cartao: formaContribCredito,
        boleto: values.boleto === false,
        pix: values.pix  === false,
        recorrente: doacaoRecorrente,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: cepLimpo,
        uf: values.uf,
      },
      entidade: {
        nome: 'osid'
      },
      campanha:{
        descricao: 'teste',
      },
      cartao: {
        number: values.number,
        verification_value: values.verification_value,
        first_name: values.first_name,
        last_name: values.last_name,
        month: values.month,
        year: values.year,
      }
    };

    //console.log('entrou aqui')

    const { error, response } = await services.CreatePessoaCredito(createPessoaCredito);

    if (!error) {
      await(setOpenModalTermo(true));

    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");
      //await(setOpenModalTermo(true));
    }

    setLoadingCreatePessoaCredito(false);

    //window.location.reload();
  }

  async function onSubmitPix(values: any) {

    setLoadingCreatePessoaPix(true);
    setLoading(true);
    //console.log(values);

    const services = new Service();

    function limparValor(valor: string): string {
      return valor.replace(/[^\d]+/g, '');
    }
    const cepLimpo = limparValor(values.cep);

    const createPessoaPix: IRequestCreatePessoa = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
        indicador: values.indicador,
        leu_comunicado_privacidade: checkedTermo2,
        concede_compartilhamento_dados: checkedTermo1,
      },
      doacao: {
        valor: parseInt(valorModifyValorDebito),

        cartao: values.cartao === false,
        boleto: values.boleto === false,
        pix: formaContribPix,
        recorrente: doacaoRecorrente,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: cepLimpo,
        uf: values.uf,
      },
      entidade: {
        nome: 'osid'
      },
      campanha:{
        descricao: 'teste',
      }
    };

    //console.log('entrou aqui')

    const { error, response } = await services.CreatePessoa(createPessoaPix);

    if (!error) {
      window.open(response, '_blank');
      await(setOpenModalTermo(true));


    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");
      //await(setOpenModalTermo(true));
    }

    setLoadingCreatePessoaPix(false);

    //window.location.reload();
  }


  async function onSubmitBoleto(values: any){

    setLoadingCreatePessoaBoleto(true);
    setLoading(true);
    //console.log(values);

    const services = new Service();

    function limparValor(valor: string): string {
      return valor.replace(/[^\d]+/g, '');
    }
    const cepLimpo = limparValor(values.cep);

    const createPessoaBoleto: IRequestCreatePessoa = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
        indicador: values.indicador,
        leu_comunicado_privacidade: checkedTermo2,
        concede_compartilhamento_dados: checkedTermo1,
      },
      doacao: {
        valor: parseInt(valorModifyValorDebito),

        cartao: values.cartao === false,
        boleto: formaContribBoleto,
        pix: values.pix === false,
        recorrente: doacaoRecorrente,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: cepLimpo,
        uf: values.uf,
      },
      entidade: {
        nome: 'osid'
      },
      campanha:{
        descricao: 'teste',
      }
    };

    //console.log('entrou aqui')

    const { error, response } = await services.CreatePessoa(createPessoaBoleto);

    if (!error) {
      window.open(response);
      await(setOpenModalTermo(true));


    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");
     // await(setOpenModalTermo(true));
    }

    setLoadingCreatePessoaBoleto(false);

    //window.location.reload();
  }

  async function onSubmitDebito(values: any) {

    setLoadingCreatePessoaDebito(true);
    setLoading(true);
    //console.log(values);

    const services = new Service();

    function limparValor(valor: string): string {
      return valor.replace(/[^\d]+/g, '');
    }
    const cepLimpo = limparValor(values.cep);

    const CreatePessoaDebito: IRequestCreatePessoaDebito = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
        indicador: '',
        leu_comunicado_privacidade: checkedTermo2,
        concede_compartilhamento_dados: checkedTermo1,
      },
      //doacao: {
        //valor: (valorModifyValorDebito) / 100 * 100,
      //},
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: cepLimpo,
        uf: values.uf,
      },
      entidade: {
        nome: 'osid'
      },
      campanha:{
        descricao: 'teste',
      },
      debito_automatico: {
        convenio: values.convenio,
        vencimento: values.vencimento,
        numero_agencia: values.numero_agencia,
        digito_agencia: values.digito_agencia,
        numero_conta: values.numero_conta,
        digito_conta: values.digito_conta,
        operacao: values.operacao,
        valor: parseInt(valorModifyValorDebito),
      }
    };

    //console.log('entrou aqui')

    const { error, response } = await services.CreatePessoaDebito(CreatePessoaDebito);

    if (!error) {
      await(setOpenModalTermo(true));

    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");
      //await(setOpenModalTermo(true));
    }

    setLoadingCreatePessoaDebito(false);

    //window.location.reload();
  }

  async function onSubmitEnergia(values: any) {

    setLoadingCreatePessoaEnergia(true);
    setLoading(true);
    //console.log(values);

    const services = new Service();

    function limparValor(valor: string): string {
      return valor.replace(/[^\d]+/g, '');
    }
    const cepLimpo = limparValor(values.cep);

    const createPessoaEnergia: IRequestCreatePessoaEnergia = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
        indicador: values.indicador,
        leu_comunicado_privacidade: checkedTermo2,
        concede_compartilhamento_dados: checkedTermo1,
      },
      //doacao: {
       // valor: (valorModifyValorDebito) / 100 * 100,
      //},
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: cepLimpo,
        uf: values.uf,
      },
      entidade: {
        nome: 'osid'
      },
      campanha:{
        descricao: 'teste',
      },
      debito_coelba: {
        numero_conta_energia: values.numero_conta_energia,
        recorrencia: values.recorrencia,
        valor: parseInt(valorModifyValorDebito),

      }
    };

    //console.log('entrou aqui')

    const { error, response } = await services.CreatePessoaEnergia(createPessoaEnergia);

    if (!error) {
      await(setOpenModalTermo(true));

    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");
      //await(setOpenModalTermo(true));
    }

    setLoadingCreatePessoaEnergia(false);

    //window.location.reload();
  }

  const searchCEPDebounce = async (value: string) => {
    const searchCEPCorreios = new SearchCEPCorreios();

    setLoadingCEP(true);
    const { error, response } = await searchCEPCorreios.SearchCEP(value);
    setLoadingCEP(false);
    if (!error && response) {
      setUF(response.uf);
      formPessoaRef.setFieldsValue({
        descricao: response.logradouro,
        bairro: response.bairro,
        uf: response.uf,
        cidade: response.localidade,
      });
    }
  };

  const debounceCEP = useDebounce(searchCEPDebounce, 800);

  const onChangeDocumento = (e: any) => {
    const value = e.target.value;

    const { typeDocument, valueModify } = MaskCPFeCNPJ(value);

    setTipoDocumento(typeDocument);


    formPessoaRef.setFieldsValue({
      cpfcnpj: valueModify,
    });

    if (typeDocument === 'pj') {
      formPessoaRef.setFieldsValue({
        sexo: 1,
      });
    }
  };


  const validaDocumento = (_: any, val: any) => {
    const documentoExiste = validaCPFandCNPJ(val);

    if (documentoExiste) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('Digite um documento válido'));
  };

  //function limparForm() {
   // document.querySelector(formPessoaRef)?.onreset();
    //var ss = e.target.selectionStart;
    //var se = e.target.selectionEnd;
    //e.target.value = e.target.value.toUpperCase();
    //e.target.selectionStart = ss;
    //e.target.selectionEnd = se;
  //};


  const onApprove = async () => {
    window.location.reload();
      await(setOpenModalTermo(true));
}

  return (
    <>
      <Grid container>

        {/* ------------------- BACKGROUND ----------------------- */}
        <Grid container item lg={12} sm={12} xs={12}
          style={{
            backgroundImage: `url('https://image-emails.s3.sa-east-1.amazonaws.com/OSID/bg-osid-doe.png')`,
            //backgroundSize: '100% 100%',
            backgroundSize: 'cover',
            //height: '90vh',
            //width: '99.3vw',
            position: 'static',
            //height: 1076,
            backgroundPosition: 'center',
            //padding: 5,
            //paddingTop: 0,
          }}
        >
          <InvisibleContent visible={openModalTermo === false}>
          <Form
            form={formPessoaRef}
            size="middle"
            layout="vertical"
            scrollToFirstError={true}
            //onError={() => HandleChangeEtapa(0)}
            labelAlign="left"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            requiredMark={false}
            initialValues={{
              nome: '',
              cpfcnpj: '',
              email: '',
              descricao: '',
              numero: '',
              complemento: '',
              bairro: '',
              cidade: '',
              cep: '',
              uf: '',
              indicador: '',
            }}
          >

            {/* ----------------------------- ETAPA 0 (PRIMEIRA PÁGINA) --------------------*/}

            <Grid container style={{ display: etapa === 0 ? 'flex' : 'none' }}>
              <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 121}}>
                <Grid container item lg={12} sm={12} xs={12} justify="center">
                  <img src={LogoCampanha} style={{ height: 200, width: 280}} />
                </Grid>

                <Grid container item lg={12} sm={12} xs={12}>
                  <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                  <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                    <p>
                      A sua doação mensal, é uma das mais importantes fontes de recursos da instituição voltadas à manutenção das atividades em prol da população em vulnerabilidade social.
                      Qualquer pessoa, física ou jurídica, pode se tornar um <strong>Sócio-Protetor</strong>, contribuindo através de boleto bancário, débito em conta, PIX, cartão de crédito ou conta de energia.
                      Ao se tornar um <strong>Sócio-Protetor</strong>, você fortalece a rede de solidariedade e acompanha as nossas atividades, realizações, campanhas e prestação de contas.
                    </p>
                  </Grid>
                  <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                </Grid>

                <Grid container  lg={12} sm={12} xs={12} style={{paddingTop: 30, paddingBottom: 120}} >
                  <Grid item  container justify="center"lg={2} sm={2}  xs={12} style={{marginLeft: 85}}></Grid>
                  <Grid item container justify="center" lg={6} sm={6}  xs={12}>
                    <img src={SocioProtetor} style={{ width: 210, height: 50}} />
                    <Button
                      color="secondary"
                      style={{height: 75, width: 350}}
                      //onClick={() => setLoading(true)}
                      onClick={() => HandleChangeEtapa(1)}
                    >
                      Sim, quero ser Doador(a) das Obras Sociais Irmã Dulce!
                      <img src={Proximo} style={{ height: 40, width: 40, marginRight: 15}} />
                    </Button>
                  </Grid>
                  <Grid item  container justify="center" lg={5} sm={5} xs={12} style={{paddingBottom: 0}}></Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* ----------------------------- ETAPA 1 (DOAÇÃO ÚNICA / DOAÇÃO RECORRENTE) --------------------*/}
            <Grid container style={{ display: etapa === 1 ? 'flex' : 'none' }}>
              <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 55}}>

                <Grid  container item lg={12} sm={12} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                  <p style={{color: 'transparent'}}>
                    A sua doação mensal, é uma das mais importantes fontes de recursos da instituição
                  </p>
                </Grid>

                <Grid container item lg={12} sm={12} xs={12} justify="center">
                  <img src={LogoCampanha} style={{ height: 200, width: 280}} />
                </Grid>

                <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 38}}>
                  <Form.Item name='recorrente'>
                    <Button
                      color="secondary"
                      style={{height: 75, width: 250, fontSize: 16, paddingLeft: 45}}
                      onClick={() => {HandleChangeEtapa(2); setDoacaoRecorrente(true); setDoacaoPaypal(false)}}
                      //onChange={() => setDoacaoRecorrente(true)}
                    >
                      Ser um doador recorrente
                      <img src={Proximo} style={{ height: 40, width: 40, marginRight: 20}} />
                    </Button>
                  </Form.Item>
                </Grid>

                <Grid  container item lg={12} sm={12} xs={12}  style={{marginTop: 10}}></Grid>

                <Grid  container item lg={12} sm={12} xs={12} justify="center">
                  <Form.Item name='recorrente'>
                    <Button
                      color="primary"
                      style={{height: 75, width: 250, fontSize: 16, paddingLeft: 45}}
                      onClick={() => {HandleChangeEtapa(2); setDoacaoRecorrente(false); setDoacaoPaypal(false)}}
                      //onChange={() => setDoacaoRecorrente(false)}
                    >
                      Realizar uma doação única
                      <img src={Proximo} style={{ height: 40, width: 40, marginRight: 20}} />
                    </Button>
                  </Form.Item>
                </Grid>

                <Grid  container item lg={12} sm={12} xs={12}  style={{marginTop: 10}}></Grid>

                <Grid  container item lg={12} sm={12} xs={12} justify="center">
                  <Form.Item name='recorrente'>
                    <Button
                      color="quartenary"
                      style={{height: 75, width: 250, fontSize: 16, paddingLeft: 45}}
                      onClick={() => {HandleChangeEtapa(3); setDoacaoPaypal(true); setDoacaoRecorrente(false)}}
                      //onChange={() => setDoacaoRecorrente(false)}
                    >
                      <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: '80', fontWeight: 'bold', color: '#003385', textAlign: 'left', }}>Pay</p>
                      <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: '80', fontWeight: 'bold', color: '#0E9EDC', textAlign: 'left', paddingRight: 20,}}>Pal</p>
                      <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                    </Button>
                    <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: '10', color: '#4f4d4d', textAlign: 'center',}}>Doação internacional</p>


                  </Form.Item>
                </Grid>


               {/*  <Grid  container item lg={12} sm={12} xs={12} justify="center">
                  <Form.Item name='teste'>
                    <Button

                      color="primary"
                      style={{height: 75, width: 250, fontSize: 16, paddingLeft: 45}}
                      onClick={() => window.open('https://www.paypal.com/cgi-bin/webscr')}
                      //onChange={() => setDoacaoRecorrente(false)}
                    >
                      TESTETETETE
                      <img src={Proximo} style={{ height: 40, width: 40, marginRight: 20}} />
                    </Button>
                  </Form.Item>
                </Grid>*/}

                <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 35, marginBottom: 5}}>
                  <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                  <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                    <Button
                      color='default'
                      onClick={() => HandleChangeEtapa(0)}
                      style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                    Voltar
                    </Button>
                  </Grid>
                  <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                </Grid>

              </Grid>
            </Grid>


            {/* ---------------- ETAPA 2 (FORMULÁRIO - DOAÇÃO RECORRENTE / DOAÇÃO ÚNICA) -------------------------------------------------------------*/}

            <Grid container style={{ display: etapa === 2 ? 'flex' : 'none' }}>
              <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 75}}>

                <Grid container item lg={12} sm={12} xs={12} justify="center">
                  <img src={LogoCampanha} style={{ height: 150, width: 210}} />
                </Grid>

                <InvisibleContent visible={doacaoRecorrente === true}>
                  <Grid container item lg={12} sm={12} xs={12} style={{marginBottom: 5}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <strong style={{ fontSize: '17px', color:'#0066B1'}}>
                      Doação recorrente
                      </strong>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

                <InvisibleContent visible={doacaoRecorrente === false}>
                  <Grid container item lg={12} sm={12} xs={12} style={{marginBottom: 5}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <strong style={{fontSize: '17px', color:'#cd8a4d'}}>
                        Doação única
                      </strong>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

                <Grid container item lg={12} sm={12} xs={12}  style={{marginTop: 10}} >
                  <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                  <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                    <p>
                     Preencha com seus dados pessoais:
                    </p>
                  </Grid>
                  <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                </Grid>

                {/* --- NOME -----*/}
                <Grid container justify="center" style={{marginTop: 10}}>
                  <InvisibleContent visible={tipoDocumento === 'pf'} >
                    <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                    <Grid item lg={4} sm={4} xs={12} justify="center">
                      <Form.Item
                        name="nome"
                        rules={[
                          { required: true, message: 'Campo obrigatório' },
                          { min: 3, message: 'Mínimo de 3 letras' },
                          {
                            pattern:  /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                            message: 'Somente letras'
                          }
                        ]}
                      >
                        <Input
                          placeholder='Nome'
                          value={''}
                          onChange={() => setFraseDoacao0(true)} onInput={handleInput}
                        />
                      </Form.Item>
                    </Grid>

                    <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                  </InvisibleContent>

                  <InvisibleContent visible={tipoDocumento === 'pj'}>
                    <Grid item lg={4} sm={4} xs={12} justify="center">
                      <Form.Item

                        name="nome"
                        rules={[
                          { required: true, message: 'Campo obrigatorio' },
                          { min: 3, message: 'Minimo de 3 letras' },

                        ]}
                      >
                        <Input
                          onChange={() => setFraseDoacao0(true)} onInput={handleInput}
                          placeholder='Razão Social'
                        />
                      </Form.Item>
                    </Grid>
                  </InvisibleContent>
                </Grid>

                {/* --- CPF/CNPJ -----*/}
                <Grid container justify="center">
                  <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                  <Grid item lg={4} sm={4} xs={12} justify="center">
                    <Form.Item
                      name="cpfcnpj"
                      rules={[
                        { required: true, message: 'Campo obrigatório' },
                        { min: 14, message: 'Minimo 14 caracteres' },
                        { validator: (e, a) => validaDocumento(e, a) },
                      ]}
                    >
                      <Input
                        maxLength={18}
                        onChange={(e: any) => {
                          onChangeDocumento(e);
                          setFraseDoacao1(true);
                        }}
                        placeholder='CPF/CNPJ'
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                </Grid>

                {/* --- DATA NASCIMENTO / CELULAR -----*/}
                <Grid container justify="center">
                  <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                  <Grid item lg={2} sm={2} xs={12} justify="center">
                    <InvisibleContent visible={tipoDocumento === 'pf'}>
                      <Grid item lg={12} sm={12} xs={12}>
                        <Form.Item
                          name="data_nascimento"
                          rules={[
                            { required: true, message: 'Campo obrigatório' },
                          ]}
                        >
                          <MaskedInput
                            mask='11/11/1111'
                            onChange={() => setFraseDoacao2(true)}
                            placeholder="Data nasc."

                          />
                        </Form.Item>
                      </Grid>
                    </InvisibleContent>

                    <InvisibleContent visible={tipoDocumento === 'pj'}>
                      <Grid item lg={12} sm={12} xs={12}>
                        <Form.Item
                          name="data_nascimento"
                          rules={[
                            { required: true, message: 'Campo obrigatorio' },
                          ]}
                        >
                          <MaskedInput
                            mask='11/11/1111'
                            onChange={() => setFraseDoacao2(true)}
                            placeholder="Data fund."
                          />
                        </Form.Item>
                     </Grid>
                    </InvisibleContent>
                  </Grid>

                  <Grid item lg={2} sm={2} xs={12} justify="center">
                    <Form.Item
                        name="telefone"
                        rules={[
                          { required: true, message: 'Campo obrigatório' },
                        ]}
                      >
                        <MaskedInput
                        onChange={() => setFraseDoacao4(true)}
                          mask="(11) 11111-1111"
                          placeholder="Celular"
                        />
                      </Form.Item>
                  </Grid>

                  <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                </Grid>

                {/* --- E-MAIL -----*/}

                <Grid container justify="center">
                  <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                  <Grid item lg={4} sm={4} xs={12} justify="center">
                    <Form.Item
                      name="email"
                      id='email'
                      rules={[
                        { required: true, message: 'Campo obrigatório' },
                        { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                        message: 'Digite um e-mail válido!' },
                      ]}
                    >
                      <Input
                        onChange={() => setFraseDoacao3(true)}
                        placeholder='E-mail'
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                </Grid>

                {/* --- DOAÇÃO RECORRENTE -----*/}

                <InvisibleContent visible={doacaoRecorrente === true}>

                  <InvisibleContent visible={fraseDoacao0 === false || fraseDoacao1 === false || fraseDoacao2 === false || fraseDoacao3 === false || fraseDoacao4 === false}>

                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 15}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitPessoa}
                      >
                        <ButtonComponent
                          color="secondary"
                          loading={loadingPessoa}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          //onClick ={() => HandleChangeEtapa(3)}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                  </Grid>
                  </InvisibleContent>

                  <InvisibleContent visible={fraseDoacao0 === true && fraseDoacao1 === true && fraseDoacao2 === true && fraseDoacao3 === true && fraseDoacao4 === true}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 15}}>

                        <ButtonComponent
                          color="secondary"
                          //loading={loadingCreatePessoaTeste}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          onClick={() => {HandleChangeEtapa(3); setConfDadosPessoais(true)}}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>

                    </Grid>
                  </InvisibleContent>

                </InvisibleContent>

                {/* --- DOAÇÃO ÚNICA -----*/}

                <InvisibleContent visible={doacaoRecorrente === false}>

                  <InvisibleContent visible={fraseDoacao0 === false || fraseDoacao1 === false || fraseDoacao2 === false || fraseDoacao3 === false || fraseDoacao4 === false}>

                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 15}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitPessoa}
                      >
                        <ButtonComponent
                          color="primary"
                          loading={loadingPessoa}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          //onClick ={() => HandleChangeEtapa(3)}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                  </Grid>
                  </InvisibleContent>

                  <InvisibleContent visible={fraseDoacao0 === true && fraseDoacao1 === true && fraseDoacao2 === true && fraseDoacao3 === true && fraseDoacao4 === true}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 15}}>

                        <ButtonComponent
                          color="primary"
                          //loading={loadingCreatePessoaTeste}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          onClick={() => {HandleChangeEtapa(3); setConfDadosPessoais(true)}}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>

                    </Grid>
                  </InvisibleContent>

                </InvisibleContent>

                <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 9, marginBottom: 80}}>
                  <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                  <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                  <Form.Item  name='teste'>
                    <Button
                      type={'reset'}
                      color='default'
                      placeholder='Voltar'
                      onClick={() => {HandleChangeEtapa(1)}}
                      style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                      Voltar

                    </Button>
                  </Form.Item>
                  </Grid>

                  <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                </Grid>

              </Grid>
            </Grid>

            {/* --------------- ETAPA 3 (VALOR - DOAÇÃO RECORRENTE / DOAÇÃO ÚNICA) ---------------------------------------------------------------*/}

            <Grid container style={{ display: etapa === 3 ? 'flex' : 'none' }}>
              <InvisibleContent visible={confDadosPessoais === true || doacaoPaypal === true}>

                <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 75}}>

                  <Grid container item lg={12} sm={12} xs={12} justify="center">
                    <img src={LogoCampanha} style={{ height: 150, width: 210}} />
                  </Grid>

                  <InvisibleContent visible={doacaoRecorrente === true && doacaoPaypal === false}>
                  <Grid container item lg={12} sm={12} xs={12} style={{marginBottom: 5}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                    <strong style={{ fontSize: '17px', color:'#0066B1'}}>
                      Doação recorrente
                    </strong>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

                <InvisibleContent visible={doacaoRecorrente === false && doacaoPaypal === false}>
                  <Grid container item lg={12} sm={12} xs={12} style={{marginBottom: 5}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <strong style={{fontSize: '17px', color:'#cd8a4d'}}>
                        Doação única
                      </strong>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

                <InvisibleContent visible={doacaoPaypal === true}>
                  <Grid container item lg={12} sm={12} xs={12} style={{marginBottom: 5}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <strong style={{fontSize: '17px', color:'#ffc439'}}>
                        Doação Paypal
                      </strong>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

                  <Grid container item lg={12} sm={12} xs={12}  style={{marginTop: 10}} >
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <p>
                      Selecione o valor da doação:
                      </p>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                  {/* --- VALOR -----*/}

                  <Grid item container justify="center" sm={12} xs={12} style={{paddingTop: 25}}>

                    <Form.Item
                      name="valorDoacao"
                      rules={[
                        { required: false, message: 'Campo obrigatório' },
                      ]}
                    >

                      <Grid item lg={12} sm={12} xs={12} container justify="center" >

                        <Grid item lg={1} sm={1} xs={12} container justify="center"></Grid>

                        <Grid item lg={9} sm={9} xs={12} container justify="center">
                          <Form.Item name="valor"
                          rules={[
                            { required: true, message: 'Campo obrigatório' },
                          ]} >

                            <Radio.Group>
                              <Grid container justify="center" style={{paddingLeft: 10}} >
                                <Grid justify="center" lg={2} sm={3} xs={4} style={{paddingRight: 95, paddingTop: 5}}>
                                <Radio  value={10} onClick={(e: any) => setValorDoacao(e)} onChange={(e: any) => {setValorModifyValorDebito(e.target.value); setValorPaypal1(e.target.value);  setFraseDoacao5(true); setFraseDoacao6(false); setFraseDoacao7(false); setFraseDoacao8(false); setFraseDoacao9(false)}} >
                                  <p><strong style={{color:'#a1a1a1', fontSize: 20}}> R$ 10 </strong> </p></Radio>
                                </Grid>

                                <Grid justify="center" lg={2} sm={3} xs={4} style={{paddingRight: 60, paddingTop: 5}}>
                                <Radio value={30} onClick={(e: any) => setValorDoacao(e)} onChange={(e: any) => {setValorModifyValorDebito(e.target.value); setValorPaypal2(e.target.value); setFraseDoacao6(true); setFraseDoacao5(false); setFraseDoacao7(false); setFraseDoacao8(false); setFraseDoacao9(false)}} >
                                  <p><strong style={{color:'#a1a1a1', fontSize: 20}}> R$ 30 </strong> </p></Radio>
                                </Grid>

                                <Grid justify="center" lg={2} sm={3} xs={4} style={{paddingRight: 60, paddingTop: 5}}>
                                <Radio value={60} onClick={(e: any) => setValorDoacao(e)} onChange={(e: any) => {setValorModifyValorDebito(e.target.value); setValorPaypal3(e.target.value); setFraseDoacao7(true); setFraseDoacao5(false); setFraseDoacao6(false); setFraseDoacao8(false); setFraseDoacao9(false)}} >
                                  <p><strong style={{color:'#a1a1a1', fontSize: 20}}> R$ 60 </strong> </p></Radio>
                                </Grid>

                                <Grid justify="center" lg={2} sm={3} xs={4} style={{paddingRight: 60, paddingTop: 5}}>
                                <Radio value={90}  onClick={(e: any) => setValorDoacao(e)} onChange={(e: any) => {setValorModifyValorDebito(e.target.value); setValorPaypal4(e.target.value); setFraseDoacao8(true); setFraseDoacao5(false); setFraseDoacao6(false); setFraseDoacao7(false); setFraseDoacao9(false)}} >
                                  <p><strong style={{color:'#a1a1a1', fontSize: 20}}> R$ 90 </strong> </p></Radio>
                                </Grid>

                                <InvisibleContent
                                  visible={valorDoacao != true}
                                >
                                  <Grid justify="center" lg={2} sm={3} xs={4} style={{paddingRight: 60, paddingTop: 5}}>
                                    <Radio value={''}  onChange={() => setValorDoacao(true)} >
                                      <p><strong style={{color:'#a1a1a1', fontSize: 20}}> R$ _ </strong> </p>
                                    </Radio>
                                  </Grid >
                                </InvisibleContent>

                                <InvisibleContent
                                  visible={valorDoacao === true}
                                >
                                <Grid justify="center" lg={2} sm={3} xs={4} style={{paddingRight: 60, paddingTop: 3}}>
                                      <Form.Item name="valor"
                                        rules={[
                                        {  pattern: /^[0-9]+$/, message: 'Somente números!'},
                                        ]}>
                                        <Input
                                          style={{ height: 89, width: 88}}
                                          type={"text"}
                                          placeholder='R$ 0,00'
                                          onChange={(e: any) => {
                                            formRef.setFieldsValue({
                                              valor: isReal(e.target.value),
                                            });
                                            setValorModifyValorDebito(e.target.value);
                                            setValorPaypal5(e.target.value);
                                            setFraseDoacao9(true);
                                            setFraseDoacao5(false); setFraseDoacao6(false); setFraseDoacao7(false); setFraseDoacao8(false)
                                          }}
                                        />

                                      </Form.Item>
                                  </Grid>

                                </InvisibleContent>
                              </Grid>
                            </Radio.Group>
                          </Form.Item>
                        </Grid>
                        <Grid justify="center" lg={1} sm={1} xs={1} style={{paddingRight: 1, paddingTop: 5}}></Grid>
                      </Grid>

                      <Grid item lg={12} sm={12} xs={12} container justify="center"></Grid>
                    </Form.Item>
                  </Grid>

                  {/* DOAÇÃO RECORRENTE */}
                  <InvisibleContent visible={doacaoRecorrente === true && doacaoPaypal === false}>
                    <InvisibleContent visible={fraseDoacao5 === false && fraseDoacao6 === false && fraseDoacao7 === false && fraseDoacao8 === false && fraseDoacao9 === false}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 49}}>
                        <Form
                          form={formPessoaRef}
                          scrollToFirstError={true}
                          onFinish={onSubmitPessoa}
                        >
                          <ButtonComponent
                            color="secondary"
                            loading={loadingPessoa}
                            type='submit'
                            style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                            //onClick ={() => HandleChangeEtapa(3)}
                          >
                            Continuar
                            <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                          </ButtonComponent>
                        </Form>
                      </Grid>
                    </InvisibleContent>

                    <InvisibleContent visible={fraseDoacao5 === true || fraseDoacao6 === true || fraseDoacao7 === true || fraseDoacao8 === true || fraseDoacao9 === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 49}}>
                        <ButtonComponent
                          color="secondary"
                          //loading={loadingCreatePessoaTeste}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          onClick={() => {HandleChangeEtapa(4); setConfValorDoacao(true)}}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Grid>
                    </InvisibleContent>
                  </InvisibleContent>

                  {/* DOAÇÃO UNICA */}
                  <InvisibleContent visible={doacaoRecorrente === false && doacaoPaypal === false}>
                    <InvisibleContent visible={fraseDoacao5 === false && fraseDoacao6 === false && fraseDoacao7 === false && fraseDoacao8 === false && fraseDoacao9 === false}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 49}}>
                        <Form
                          form={formPessoaRef}
                          scrollToFirstError={true}
                          onFinish={onSubmitPessoa}
                        >
                          <ButtonComponent
                            color="primary"
                            loading={loadingPessoa}
                            type='submit'
                            style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                            //onClick ={() => HandleChangeEtapa(3)}
                          >
                            Continuar
                            <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                          </ButtonComponent>
                        </Form>
                      </Grid>
                    </InvisibleContent>

                    <InvisibleContent visible={fraseDoacao5 === true || fraseDoacao6 === true || fraseDoacao7 === true || fraseDoacao8 === true || fraseDoacao9 === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 49}}>
                        <ButtonComponent
                          color="primary"
                          //loading={loadingCreatePessoaTeste}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          onClick={() => {HandleChangeEtapa(7); setConfValorDoacao(true)}}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Grid>
                    </InvisibleContent>
                  </InvisibleContent>

                  {/* DOAÇÃO PAYPAL */}
                  <InvisibleContent visible={doacaoPaypal === true }>
                    <InvisibleContent visible={fraseDoacao5 === false && fraseDoacao6 === false && fraseDoacao7 === false && fraseDoacao8 === false && fraseDoacao9 === false}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 49}}>
                        <Form
                          form={formPessoaRef}
                          scrollToFirstError={true}
                          onFinish={onSubmitPessoa}
                        >
                          <ButtonComponent
                            color="quartenary"
                            loading={loadingPessoa}
                            type='submit'
                            style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                            //onClick ={() => HandleChangeEtapa(3)}
                          >
                            Continuar
                            <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                          </ButtonComponent>
                        </Form>
                      </Grid>
                    </InvisibleContent>

                    <InvisibleContent visible={fraseDoacao5 === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 75}}>
                      <ButtonComponent
                            color="quartenary"
                            loading={loadingPessoa}
                            type='submit'
                            style={{height: 40, width: 160, fontSize: 16, paddingLeft: 10}}
                            //onClick ={() => HandleChangeEtapa(3)}
                          >
                             <PayPalButton
                          paypalOptions={{clientId: "AUXC9WOIHE4ORLR6smQIbH9Txam9pVsF1ytZC9Ry778OejykvOfmrStuGVin9n3Ae2zGTTWiSbXujOYZ", intent: 'capture', currency: "BRL", }}
                          buttonStyles={{layout: 'horizontal',
                          shape: undefined, tagline: true, label: "paypal", color: "gold"}}
                          amount={valorPaypal1}
                          onPaymentSuccess={onApprove}
                        />
                          </ButtonComponent>


                      </Grid>
                    </InvisibleContent>

                    <InvisibleContent visible={fraseDoacao6 === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 75}}>
                      <ButtonComponent
                            color="quartenary"
                            loading={loadingPessoa}
                            type='submit'
                            style={{height: 40, width: 160, fontSize: 16, paddingLeft: 10}}
                            //onClick ={() => HandleChangeEtapa(3)}
                          >
                             <PayPalButton
                          paypalOptions={{clientId: "AUXC9WOIHE4ORLR6smQIbH9Txam9pVsF1ytZC9Ry778OejykvOfmrStuGVin9n3Ae2zGTTWiSbXujOYZ", intent: 'capture', currency: "BRL", }}
                          buttonStyles={{layout: 'horizontal',
                          shape: undefined, tagline: true, label: "paypal", color: "gold"}}
                          amount={valorPaypal2}
                          onPaymentSuccess={onApprove}
                        />
                          </ButtonComponent>

                      </Grid>
                    </InvisibleContent>

                    <InvisibleContent visible={fraseDoacao7 === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 75}}>
                      <ButtonComponent
                            color="quartenary"
                            loading={loadingPessoa}
                            type='submit'
                            style={{height: 40, width: 160, fontSize: 16, paddingLeft: 10}}
                            //onClick ={() => HandleChangeEtapa(3)}
                          >
                             <PayPalButton
                          paypalOptions={{clientId: "AUXC9WOIHE4ORLR6smQIbH9Txam9pVsF1ytZC9Ry778OejykvOfmrStuGVin9n3Ae2zGTTWiSbXujOYZ", intent: 'capture', currency: "BRL", }}
                          buttonStyles={{layout: 'horizontal',
                          shape: undefined, tagline: true, label: "paypal", color: "gold"}}
                          amount={valorPaypal3}
                          onPaymentSuccess={onApprove}
                        />
                          </ButtonComponent>

                      </Grid>
                    </InvisibleContent>

                    <InvisibleContent visible={fraseDoacao8 === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 75}}>
                      <ButtonComponent
                            color="quartenary"
                            loading={loadingPessoa}
                            type='submit'
                            style={{height: 40, width: 160, fontSize: 16, paddingLeft: 10}}
                            //onClick ={() => HandleChangeEtapa(3)}
                          >
                             <PayPalButton
                          paypalOptions={{clientId: "AUXC9WOIHE4ORLR6smQIbH9Txam9pVsF1ytZC9Ry778OejykvOfmrStuGVin9n3Ae2zGTTWiSbXujOYZ", intent: 'capture', currency: "BRL", }}
                          buttonStyles={{layout: 'horizontal',
                          shape: undefined, tagline: true, label: "paypal", color: "gold"}}
                          amount={valorPaypal4}
                          onPaymentSuccess={onApprove}
                        />
                          </ButtonComponent>
                      </Grid>
                    </InvisibleContent>

                    <InvisibleContent visible={fraseDoacao9 === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 75}}>
                      <ButtonComponent
                            color="quartenary"
                            loading={loadingPessoa}
                            type='submit'
                            style={{height: 40, width: 160, fontSize: 16, paddingLeft: 10}}
                            //onClick ={() => HandleChangeEtapa(3)}
                          >
                             <PayPalButton
                          paypalOptions={{clientId: "AUXC9WOIHE4ORLR6smQIbH9Txam9pVsF1ytZC9Ry778OejykvOfmrStuGVin9n3Ae2zGTTWiSbXujOYZ", intent: 'capture', currency: "BRL", }}
                          buttonStyles={{layout: 'horizontal',
                          shape: undefined, tagline: true, label: "paypal", color: "gold"}}
                          amount={valorPaypal5}
                          onPaymentSuccess={onApprove}
                        />
                          </ButtonComponent>

                      </Grid>
                    </InvisibleContent>

                  </InvisibleContent>

                  <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 9, marginBottom: 85}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <InvisibleContent visible={doacaoPaypal === false}>
                        <Button
                          color='default'
                          onClick={() => HandleChangeEtapa(2)}
                          style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                            Voltar
                        </Button>
                      </InvisibleContent>

                      <InvisibleContent visible={doacaoPaypal === true}>
                        <Button
                          color='default'
                          onClick={() => HandleChangeEtapa(1)}
                          style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                            Voltar
                        </Button>
                      </InvisibleContent>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                </Grid>
              </InvisibleContent>
            </Grid>

            {/* ------------- ETAPA 4 (FORMA DE PAGAMENTO - DOAÇÃO RECORRENTE) -----------------------------------------------------------------*/}

            <Grid container style={{ display: etapa === 4 ? 'flex' : 'none' }}>
              <InvisibleContent visible={confValorDoacao === true}>

                <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 75}}>

                  <Grid container item lg={12} sm={12} xs={12} justify="center">
                    <img src={LogoCampanha} style={{ height: 150, width: 210}} />
                  </Grid>

                  <Grid container item lg={12} sm={12} xs={12} style={{marginBottom: 5}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                    <strong style={{ fontSize: '17px', color:'#0066B1'}}>
                      Doação recorrente
                    </strong>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                  <Grid container item lg={12} sm={12} xs={12}  style={{marginTop: 10}} >
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <p style={{fontSize: '14px',}}>
                      Selecione a forma de pagamento da doação:
                      </p>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                  {/* --- VALOR -----*/}

                  <Grid item container justify="center" sm={12} xs={12} style={{paddingTop: 13}}>

                    <Form.Item
                      name="doacao"
                      rules={[
                        { required: false, message: 'Campo obrigatório' },
                      ]}
                    >

                      <Grid item lg={12} sm={12} xs={12} container justify="center" >

                        <Grid item lg={1} sm={1} xs={12} container justify="center"></Grid>

                        <Grid item lg={9} sm={9} xs={12} container justify="center">
                          <Form.Item
                            name="formaDoacao"
                            rules={[
                              { required: true, message: 'Campo obrigatório' },
                            ]}
                          >
                            <Radio.Group>
                              <Grid container justify="center" style={{paddingLeft: 10}} >
                                <Grid justify="center" lg={2} sm={3} xs={4} style={{paddingRight: 95, paddingTop: 5}}>
                                  <Form.Item  name='boleto' valuePropName="checked">
                                    <Radio name='boleto' value="boleto" style={{height: 110, width: 98}} onChange={() => setFormaContribBoleto(true)} onClick={(e: any) => {setFormaContribPix(false); setFormaContribCredito(false); setFormaContribDebito(false); setFormaContribEnergia(false) }} >
                                      <img src={Boleto} style={{ height: 20, width: 25, marginLeft: 10, marginTop: 1}} />
                                      <p> <strong style={{ color: '#a1a1a1',  marginLeft: 8, fontSize: 12, marginTop: 2, textAlign: 'center' }}> Boleto </strong></p>
                                    </Radio>
                                  </Form.Item>
                                </Grid>

                                <Grid justify="center" lg={2} sm={3} xs={4} style={{paddingRight: 60, paddingTop: 5}}>
                                  <Form.Item  name='pix' valuePropName="checked">
                                    <Radio name='pix' value="pix" style={{height: 110, width: 98}} onChange={() => setFormaContribPix(true)} onClick={(e: any) => {setFormaContribCredito(false); setFormaContribBoleto(false); setFormaContribDebito(false); setFormaContribEnergia(false)}} >
                                      <img src={Pix} style={{ height: 18, width: 23, marginLeft: 19, marginTop: 1}} />
                                      <p> <strong style={{ color: '#a1a1a1', marginLeft: 20, fontSize: 13, textAlign: 'center', marginTop: 2}}> PIX </strong></p>
                                    </Radio>
                                  </Form.Item>
                                </Grid>

                                <Grid justify="center" lg={2} sm={3} xs={4} style={{paddingRight: 60, paddingTop: 5}}>
                                  <Form.Item  name='cartao' valuePropName="checked">
                                    <Radio name='cartao' value="cartao" style={{height: 110, width: 98}} onChange={() => setFormaContribCredito(true)} onClick={(e: any) => {setFormaContribPix(false); setFormaContribBoleto(false); setFormaContribDebito(false); setFormaContribEnergia(false) }} >
                                      <img src={Cartao} style={{ height: 15, width: 20, marginRight: 20,  marginTop: 1}} />
                                      <p style={{ color: '#a1a1a1', textAlign: 'center', marginRight: 15, fontSize: 12,  marginTop: 2, lineHeight: 1.1, fontWeight: 600}}> Cartão de crédito </p>
                                    </Radio>
                                  </Form.Item>
                                </Grid>

                                <Grid justify="center" lg={2} sm={3} xs={4} style={{paddingRight: 60, paddingTop: 5}}>
                                  <Form.Item name='debito' valuePropName="checked">
                                    <Radio name='debito' value="debito" style={{height: 110, width: 98}} onChange={() => setFormaContribDebito(true)} onClick={(e: any) => {setFormaContribPix(false); setFormaContribBoleto(false); setFormaContribCredito(false); setFormaContribEnergia(false) }}>
                                      <img src={Debito} style={{ height: 20, width: 20, marginRight: 50,  marginTop: 1}} />
                                      <p style={{ color: '#a1a1a1', marginRight: 50, fontSize: 12,  marginTop: 2,  textAlign: 'center', lineHeight: 1.1, fontWeight: 600}}> Débito automático </p>
                                    </Radio>
                                  </Form.Item>
                                </Grid>

                                <Grid justify="center" lg={2} sm={3} xs={4} style={{paddingRight: 60, paddingTop: 5}}>
                                  <Form.Item name='energia' valuePropName="checked">
                                    <Radio name='energia' value="energia" style={{height: 110, width: 98}}  onChange={() => setFormaContribEnergia(true)} onClick={(e: any) => {setFormaContribPix(false); setFormaContribBoleto(false); setFormaContribDebito(false); setFormaContribCredito(false) }}>
                                      <img src={Energia} style={{ height: 20, width: 15, marginRight: 20,  marginTop: 1}} />
                                      <p style={{ color: '#a1a1a1', textAlign: 'center', marginRight: 15, fontSize: 12,  marginTop: 2, lineHeight: 1.1, fontWeight: 600}}> Conta de energia </p>
                                    </Radio>
                                  </Form.Item>
                                </Grid >

                              </Grid>
                            </Radio.Group>
                          </Form.Item>
                        </Grid>
                        <Grid justify="center" lg={1} sm={1} xs={1} style={{paddingRight: 1, paddingTop: 5}}></Grid>
                      </Grid>

                      <Grid item lg={12} sm={12} xs={12} container justify="center"></Grid>
                    </Form.Item>
                  </Grid>

                  <InvisibleContent visible={formaContribBoleto === false && formaContribCredito === false && formaContribDebito === false && formaContribEnergia === false && formaContribPix === false}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 43}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitPessoa}
                      >
                        <ButtonComponent
                          color="secondary"
                          loading={loadingPessoa}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          //onClick ={() => HandleChangeEtapa(3)}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                    </Grid>
                  </InvisibleContent>

                  <InvisibleContent visible={formaContribBoleto === true || formaContribCredito === true || formaContribPix === true || formaContribDebito === true || formaContribEnergia === true}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 43}}>
                      <ButtonComponent
                        color="secondary"
                        //loading={loadingCreatePessoaTeste}
                        type='submit'
                        style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                        onClick={() => {HandleChangeEtapa(5); setConfFormaContrib(true)}}
                      >
                        Continuar
                        <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                      </ButtonComponent>
                    </Grid>
                  </InvisibleContent>

                  <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 9, marginBottom: 85}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <Button
                        color='default'
                        onClick={() => HandleChangeEtapa(3)}
                        style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                      Voltar
                      </Button>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                </Grid>
              </InvisibleContent>
            </Grid>

            {/* ---------------- ETAPA 5 (ENDEREÇO - DOAÇÃO RECORRENTE) ------------------------------------------------------------*/}

            <Grid container style={{ display: etapa === 5 ? 'flex' : 'none' }}>
              <InvisibleContent visible={confFormaContrib === true}>
                <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 75}}>

                  <Grid container item lg={12} sm={12} xs={12} justify="center">
                    <img src={LogoCampanha} style={{ height: 150, width: 210}} />
                  </Grid>

                  <Grid container item lg={12} sm={12} xs={12} style={{marginBottom: 5}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                    <strong style={{ fontSize: '17px', color:'#0066B1'}}>
                      Doação recorrente
                    </strong>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                  <Grid container item lg={12} sm={12} xs={12}  style={{marginTop: 10}} >
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <p>
                        Preencha com seus dados de endereçamento
                      </p>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                  <InvisibleContent visible={formaContribEnergia === true}>
                    <Grid container item lg={12} sm={12} xs={12}  style={{marginTop: 10}} >
                      <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                      <Grid  container item lg={5} sm={5} xs={12} justify="center" style={{marginLeft: 5, marginRight: 5}}>

                        <ReportGmailerrorred style={{color: '#CC0000', height: 15, width: 15}} >
                        </ReportGmailerrorred>
                        <p style={{textAlign: 'left', fontSize: '11px',}}>
                        Somente pessoas que moram no estado da <strong style={{color:'#0066B1'}}> BAHIA </strong>que realizarão a doação por meio da conta de energia!
                        </p>
                        </Grid>

                      <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                    </Grid>
                  </InvisibleContent>

                  <Grid container justify="center" style={{marginTop: 10}}>
                    <Spin spinning={loadingCEP} tip="Buscando dados do CEP">

                      {/* --- CEP -----*/}
                      <Grid container justify="center">
                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center">
                          <Form.Item
                            name="cep"
                            rules={[
                              { required: true, message: 'Campo obrigatório' }
                            ]}
                          >
                            <MaskedInput
                              mask="11111-111"
                              onChange={(e: any) => {debounceCEP(e.target.value); setFraseDoacao10(true)}}
                              placeholder='CEP'
                            />
                          </Form.Item>
                        </Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                      </Grid>

                      {/* --- UF / CIDADE -----*/}
                      <Grid container justify="center">
                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                        <Grid item lg={2} sm={2} xs={12} justify="center">
                          <Form.Item
                            name="uf"
                            rules={[{ required: true, message: "Campo obrigatório" },
                              {max: 2, message: "Somente sigla"}
                            ]}
                          >
                            <Input
                              onInput={handleInput}
                              onChange={(e: any) => {
                              //onChangeDocumento(e);
                              setFraseDoacao11(true);
                              setUF(e.target.value);
                              }}
                              placeholder='Estado'

                            />
                          </Form.Item>
                        </Grid>

                        <Grid item lg={2} sm={2} xs={12} justify="center">
                          <Form.Item
                            name="cidade"
                            rules={[{ required: true, message: "Campo obrigatório" }]}
                          >
                            <Input
                              onInput={handleInput}
                              onChange={(e: any) =>
                                 setFraseDoacao12(true)
                              }
                              placeholder='Cidade'/>
                          </Form.Item>
                        </Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                      </Grid>

                      {/* --- ENDEREÇO -----*/}
                      <Grid container justify="center">
                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center">
                          <Form.Item
                            name="descricao"
                            rules={[{ required: true, message: "Campo obrigatório" }]}
                          >
                            <Input
                              onInput={handleInput}
                              onChange={(e: any) =>
                               setFraseDoacao13(true)
                              }
                              placeholder='Endereço'/>
                          </Form.Item>
                        </Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                      </Grid>

                      {/* --- Nº / BAIRRO / COMPLEMENTO -----*/}
                      <Grid container justify="center">
                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                        <Grid item lg={1} sm={1} xs={12} justify="center">
                          <Form.Item
                            name="numero"
                            rules={[
                              { required: true, message: 'Campo obrigatório' },
                              {  pattern: /^[0-9]+$/, message: 'Somente números!'},
                            ]}
                          >
                            <Input
                              //onInput={handleInput}
                              onChange={(e: any) =>
                                setFraseDoacao14(true)
                              }
                              placeholder='Número'
                            />
                          </Form.Item>
                        </Grid>

                        <Grid item lg={2} sm={2} xs={12} justify="center">
                          <Form.Item
                            name="bairro"
                            rules={[{required: true, message: "Campo obrigatório" }]}
                          >
                            <Input
                              onInput={handleInput}
                              onChange={(e: any) =>
                                setFraseDoacao15(true)
                              }
                              placeholder='Bairro' />
                          </Form.Item>
                        </Grid>

                        <Grid item lg={1} sm={1} xs={12} justify="center">
                          <Form.Item
                            name="complemento"
                            rules={[{ required: false }]}
                          >
                            <Input
                              onInput={handleInput}
                              ///onChange={(e: any) =>
                               //setFraseDoacao16(true)
                              //}
                              placeholder='Complemento' />
                          </Form.Item>
                        </Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                      </Grid>
                    </Spin>
                  </Grid>

                  <InvisibleContent visible={fraseDoacao10 === false || fraseDoacao14 === false}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 15}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitPessoa}
                      >
                        <ButtonComponent
                          color="secondary"
                          loading={loadingPessoa}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          //onClick ={() => HandleChangeEtapa(3)}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                    </Grid>
                  </InvisibleContent>

                  <InvisibleContent visible={fraseDoacao10 === true && fraseDoacao14 === true }>
                    <InvisibleContent visible={formaContribBoleto === true || formaContribPix === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 15}}>
                        <ButtonComponent
                          color="secondary"
                          //loading={loadingCreatePessoaTeste}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          onClick={() => {HandleChangeEtapa(9);}}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Grid>
                    </InvisibleContent>
                  </InvisibleContent>

                  <InvisibleContent visible={fraseDoacao10 === true && fraseDoacao14 === true }>
                    <InvisibleContent visible={formaContribCredito === true || formaContribDebito === true || formaContribEnergia === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 15}}>
                        <ButtonComponent
                          color="secondary"
                          //loading={loadingCreatePessoaTeste}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          onClick={() => {HandleChangeEtapa(6);}}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Grid>
                    </InvisibleContent>
                  </InvisibleContent>

                  <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 9, marginBottom: 85}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <ButtonComponent
                        color='default'
                        onClick={() => HandleChangeEtapa(4)}
                        style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                      Voltar
                      </ButtonComponent>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                </Grid>
              </InvisibleContent>
            </Grid>



            {/* ------- ETAPA 6 (OUTROS DADOS FORMA DE PAGAMENTO - DOAÇÃO RECORRENTE) --------------------*/}

            <Grid container style={{ display: etapa === 6 ? 'flex' : 'none' }}>
              <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 75}}>

                <Grid container item lg={12} sm={12} xs={12} justify="center">
                  <img src={LogoCampanha} style={{ height: 150, width: 210}} />
                </Grid>

                <InvisibleContent visible={doacaoRecorrente === true}>
                  <Grid container item lg={12} sm={12} xs={12} style={{marginBottom: 5}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                    <strong style={{ fontSize: '17px', color:'#0066B1'}}>
                      Doação recorrente
                    </strong>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

                <InvisibleContent visible={doacaoRecorrente === false}>
                  <Grid container item lg={12} sm={12} xs={12} style={{marginBottom: 5}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <strong style={{fontSize: '17px', color:'#cd8a4d'}}>
                        Doação única
                      </strong>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

                {/* ----------------------------- DADOS CARTÃO DE CRÉDITO --------------------*/}

                <InvisibleContent visible={formaContribCredito === true}>

                  <Grid container item lg={12} sm={12} xs={12}  style={{marginTop: 10}} >
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <p>
                      Preencha com seus dados de cartão de crédito:
                      </p>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                  <Grid container justify="center" style={{marginTop: 10}}>

                    <Grid container justify="center">
                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                      <Grid item lg={4} sm={4} xs={12} justify="center">
                        <Form.Item
                          name="number"
                          rules={[
                            { required: true, message: 'Campo obrigatório' },
                          ]}
                        >
                          <Input
                            maxLength={16}
                            placeholder='Número do cartão'
                            onChange={(e: any) =>
                              setFraseDoacao16(true)
                            }
                          />
                        </Form.Item>
                      </Grid>

                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                    </Grid>

                    <Grid container justify="center">
                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                      <Grid item lg={2} sm={2} xs={12} justify="center">
                        <Form.Item
                          name="first_name"
                          rules={[
                            { required: true, message: 'Campo obrigatório' },
                          ]}
                        >
                          <Input
                            placeholder='Nome impresso cartão'
                            onChange={(e: any) =>
                              setFraseDoacao17(true)
                            }
                          />
                        </Form.Item>
                      </Grid>

                      <Grid item lg={2} sm={2} xs={12} justify="center">
                        <Form.Item
                          name="last_name"
                          rules={[
                            { required: true, message: 'Campo obrigatório' },
                          ]}
                        >
                          <Input placeholder='Sobrenome impresso cartão'
                          onChange={(e: any) =>
                            setFraseDoacao18(true)
                          } />
                        </Form.Item>
                      </Grid>

                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                    </Grid>

                    <Grid container justify="center">
                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                      <Grid item lg={1} sm={1} xs={12} justify="center">
                        <Form.Item
                          name="month"
                          rules={[
                            { required: true, message: 'Campo obrigatório' },
                            {  pattern: /^[0-9]+$/, message: 'Somente números!'},
                          ]}
                        >
                          <Input
                            maxLength={2}
                            minLength={2}
                            placeholder='Mês'
                            onChange={(e: any) =>
                              setFraseDoacao19(true)
                            }
                          />
                        </Form.Item>
                      </Grid>

                      <Grid item lg={2} sm={2} xs={12} justify="center">
                        <Form.Item
                          name="year"
                          rules={[
                            { required: true, message: 'Campo obrigatório' },
                            {  pattern: /^[0-9]+$/, message: 'Somente números!'},
                          ]}
                        >
                          <Input
                            maxLength={4}
                            minLength={4}
                            placeholder='Ano'
                            onChange={(e: any) =>
                              setFraseDoacao20(true)
                            }
                          />
                        </Form.Item>
                      </Grid>

                      <Grid item lg={1} sm={1} xs={12} justify="center">
                        <Form.Item
                          name="verification_value"
                          rules={[
                            { required: true, message: 'Campo obrigatório' },
                            //{  pattern: /^[0-9]+$/, message: 'Somente números!'},
                          ]}
                        >
                          <Input placeholder='CVV'
                          onChange={(e: any) =>
                            setFraseDoacao21(true)
                          }/>
                        </Form.Item>
                      </Grid>

                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                    </Grid>

                  </Grid>
                </InvisibleContent>

                {/* ----------------------------- DADOS DÉBITO AUTOMÁTICO --------------------*/}

                <InvisibleContent visible={formaContribDebito === true}>

                  <Grid container item lg={12} sm={12} xs={12}  style={{marginTop: 10}} >
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <p>
                      Preencha com os dados da sua conta bancária:
                      </p>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                  <Grid container justify="center" style={{marginTop: 10}}>

                    <Grid container justify="center">
                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                      <Grid item lg={4} sm={4} xs={12} justify="center">
                        <Form.Item
                          name="convenio"
                          rules={[
                            {required: true,},
                          ]}
                        >
                          <SelectAntd
                            onSelect={(e: any) => filterCheckTipoConvenio(e)}
                            placeholder='Convênio'
                            onChange={(e: any) =>
                              setFraseDoacao22(true)
                            }
                          >
                            {convenio.map(item => (
                              <SelectAntd.Option key={item.id} value={item.descricao}>
                                {item.descricao}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>

                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                    </Grid>

                    <Grid container justify="center">
                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                      <Grid item lg={2} sm={2} xs={12} justify="center">
                        <Form.Item
                          name="numero_agencia"
                          rules={[
                            { required: true, message: 'Campo obrigatório' },
                            {  pattern: /^[0-9]+$/, message: 'Somente números!'},
                            { max: 4, message: 'Máximo de  4 carácter' },
                           ]}
                        >
                          <Input placeholder='Número agência'
                            onChange={(e: any) =>
                              setFraseDoacao23(true)
                            } />
                        </Form.Item>
                      </Grid>

                      <Grid item lg={2} sm={2} xs={12} justify="center">
                        <Form.Item
                          name="digito_agencia"
                          rules={[
                            { required: false, message: 'Campo obrigatório' },
                            {  pattern: /^[a-zA-Z0-9]+$/, message: "Somente números ou letras"},
                            { max: 1, message: 'Máximo de  1 carácter' },
                          ]}
                        >
                          <Input placeholder='Dígito agência'
                            onChange={(e: any) =>
                              setFraseDoacao24(true)
                            }/>
                        </Form.Item>
                      </Grid>

                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                    </Grid>

                    <Grid container justify="center">
                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                      <Grid item lg={2} sm={2} xs={12} justify="center">
                        <Form.Item
                          name="numero_conta"
                          rules={[
                            { required: true, message: 'Campo obrigatório' },
                            {  pattern: /^[0-9]+$/, message: 'Somente números!'},
                          ]}
                        >
                          <Input placeholder='Número conta'
                           onChange={(e: any) =>
                            setFraseDoacao25(true)
                          }/>
                        </Form.Item>
                      </Grid>

                      <Grid item lg={1} sm={1} xs={12} justify="center">
                        <Form.Item
                          name="digito_conta"
                          rules={[
                            { required: false, message: 'Campo obrigatório' },
                            {  pattern: /^[a-zA-Z0-9]+$/, message: "Somente números ou letras"},
                          ]}
                        >
                          <Input placeholder='Dígito conta'
                            onChange={(e: any) =>
                              setFraseDoacao26(true)
                            }/>
                        </Form.Item>
                      </Grid>

                      <Grid item lg={1} sm={1} xs={12} justify="center">
                        <Form.Item
                          name="vencimento"
                          rules={[
                            { required: true, message: 'Campo obrigatório' },
                            //{  pattern: /^[0-9]+$/, message: 'Somente números!'},
                          ]}
                        >
                          <SelectAntd placeholder='Vencimento'
                          onChange={(e: any) =>
                            setFraseDoacao27(true)
                          }>
                            {data.map(item => (
                              <SelectAntd.Option key={item.id} value={item.id}>
                                {item.id}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>

                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                    </Grid>

                    <InvisibleContent
                        visible={convenioSelecionada
                          .toLocaleUpperCase()
                          .includes('SANTANDER') }
                    >
                      <Grid container justify="center">
                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center">
                          <Form.Item
                            name="operacao"
                            //label="Operação"
                            rules={[
                              {
                                required: true, message: 'Campo obrigatório'
                              },
                            ]}
                          >
                            <SelectAntd placeholder='Operação'>
                              {operacaoSantander.map(item => (
                                <SelectAntd.Option
                                  key={item.codigo_operacao}
                                  value={item.codigo_operacao}
                                >
                                  {item.codigo_operacao + ' - ' + item.descricao}
                                </SelectAntd.Option>
                              ))}
                            </SelectAntd>
                          </Form.Item>
                        </Grid>
                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                      </Grid>
                    </InvisibleContent>

                    <InvisibleContent
                      visible={convenioSelecionada
                        .includes('CAIXA') }
                    >

                      <Grid container justify="center">
                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center">
                          <Form.Item
                            name="operacao"
                            //label="Operação"
                            rules={[
                              {
                                required: true, message: 'Campo obrigatório'
                              },
                            ]}
                          >
                            <SelectAntd placeholder='Operação'>
                              {operacaoCaixa.map(item => (
                                <SelectAntd.Option
                                  key={item.codigo_operacao}
                                  value={item.codigo_operacao}
                                >
                                  {item.codigo_operacao + ' - ' + item.descricao}
                                </SelectAntd.Option>
                              ))}
                            </SelectAntd>
                          </Form.Item>
                        </Grid>
                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                      </Grid>
                    </InvisibleContent>
                    </Grid>
                </InvisibleContent>


                {/* ----------------------------- DADOS CONTA ENERGIA --------------------*/}

                <InvisibleContent visible={formaContribEnergia === true}>

                  <Grid container item lg={12} sm={12} xs={12}  style={{marginTop: 10}} >
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <p>
                      Preencha com os dados da sua conta de enegia:
                      </p>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                  <Grid container justify="center" style={{marginTop: 10}}>

                    <Grid container justify="center">
                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                      <Grid item lg={4} sm={4} xs={12} justify="center">
                        <Form.Item
                          name="numero_conta_energia"
                          rules={[
                            {required: true, message: 'Campo obrigatório' },
                            {  pattern: /^[0-9]+$/, message: 'Somente números!'},
                          ]}
                        >
                          <Input
                            disabled={uf !== 'BA'}
                            maxLength={12}
                            placeholder='Número conta'
                            onChange={(e: any) =>
                              setFraseDoacao28(true)
                            }
                          />
                        </Form.Item>
                      </Grid>

                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                    </Grid>

                    <Grid container justify="center">
                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                      <Grid item lg={4} sm={4} xs={12} justify="center">
                        <Form.Item
                          rules={[
                            { required: true, },
                          ]}
                        >
                          <SelectAntd disabled={uf !== 'BA'} placeholder='Recorrência'
                          onChange={(e: any) =>
                            setFraseDoacao29(true)
                          }>

                            {recorrencias.map(item => (
                              <SelectAntd.Option key={item.descricao} value={item.descricao}>
                                {item.id + ' - ' + item.descricao}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>
                      <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                    </Grid>
                  </Grid>

                </InvisibleContent>

                <InvisibleContent visible={formaContribCredito === true}>

                  {/* DOAÇÃO RECORRENTE */}
                  <InvisibleContent visible={doacaoRecorrente === true}>

                    <InvisibleContent visible={fraseDoacao16 === false || fraseDoacao17 === false || fraseDoacao18 === false || fraseDoacao19 === false || fraseDoacao20 === false || fraseDoacao21 === false}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 56}}>
                        <Form
                          form={formPessoaRef}
                          scrollToFirstError={true}
                          onFinish={onSubmitPessoa}
                        >
                          <ButtonComponent
                            color="secondary"
                            loading={loadingPessoa}
                            type='submit'
                            style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                              //onClick ={() => HandleChangeEtapa(3)}
                          >
                            Continuar
                            <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                          </ButtonComponent>
                        </Form>
                      </Grid>
                    </InvisibleContent>

                    <InvisibleContent visible={fraseDoacao16 === true && fraseDoacao17 === true && fraseDoacao18 === true && fraseDoacao19 === true && fraseDoacao20 === true && fraseDoacao21 === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 56}}>
                        <ButtonComponent
                          color="secondary"
                          //loading={loadingCreatePessoaTeste}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          onClick={() => {HandleChangeEtapa(9)}}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Grid>
                    </InvisibleContent>
                  </InvisibleContent>

                  {/* DOAÇÃO ÚNICA */}
                  <InvisibleContent visible={doacaoRecorrente === false}>

                    <InvisibleContent visible={fraseDoacao16 === false || fraseDoacao17 === false || fraseDoacao18 === false || fraseDoacao19 === false || fraseDoacao20 === false || fraseDoacao21 === false}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 56}}>
                        <Form
                          form={formPessoaRef}
                          scrollToFirstError={true}
                          onFinish={onSubmitPessoa}
                        >
                          <ButtonComponent
                            color="primary"
                            loading={loadingPessoa}
                            type='submit'
                            style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                              //onClick ={() => HandleChangeEtapa(3)}
                          >
                            Continuar
                            <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                          </ButtonComponent>
                        </Form>
                      </Grid>
                    </InvisibleContent>

                    <InvisibleContent visible={fraseDoacao16 === true && fraseDoacao17 === true && fraseDoacao18 === true && fraseDoacao19 === true && fraseDoacao20 === true && fraseDoacao21 === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 56}}>
                        <ButtonComponent
                          color="primary"
                          //loading={loadingCreatePessoaTeste}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          onClick={() => {HandleChangeEtapa(10)}}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Grid>
                    </InvisibleContent>
                  </InvisibleContent>
                </InvisibleContent>

                <InvisibleContent visible={formaContribDebito === true}>

                  <InvisibleContent visible={fraseDoacao22 === false || fraseDoacao23 === false || fraseDoacao24 === false || fraseDoacao25 === false || fraseDoacao26 === false || fraseDoacao27 === false}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 56}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitPessoa}
                      >
                        <ButtonComponent
                          color="secondary"
                          loading={loadingPessoa}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                            //onClick ={() => HandleChangeEtapa(3)}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                    </Grid>
                  </InvisibleContent>

                  <InvisibleContent visible={fraseDoacao22 === true && fraseDoacao23 === true && fraseDoacao24 === true && fraseDoacao25 === true && fraseDoacao26 === true && fraseDoacao27 === true}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 56}}>
                      <ButtonComponent
                        color="secondary"
                        //loading={loadingCreatePessoaTeste}
                        type='submit'
                        style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                        onClick={() => {HandleChangeEtapa(9)}}
                      >
                        Continuar
                        <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                      </ButtonComponent>
                    </Grid>
                  </InvisibleContent>
                </InvisibleContent>

                <InvisibleContent visible={formaContribEnergia === true}>

                  <InvisibleContent visible={fraseDoacao28 === false || fraseDoacao29 === false}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 97}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitPessoa}
                      >
                        <ButtonComponent
                          color="secondary"
                          loading={loadingPessoa}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                            //onClick ={() => HandleChangeEtapa(3)}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                    </Grid>
                  </InvisibleContent>

                  <InvisibleContent visible={fraseDoacao28 === true && fraseDoacao29 === true}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 97}}>
                      <ButtonComponent
                        color="secondary"
                        //loading={loadingCreatePessoaTeste}
                        type='submit'
                        style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                        onClick={() => {HandleChangeEtapa(9)}}
                      >
                        Continuar
                        <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                      </ButtonComponent>
                    </Grid>
                  </InvisibleContent>
                </InvisibleContent>


                {/* DOAÇÃO RECORRENTE */}
                <InvisibleContent visible={doacaoRecorrente === true}>

                  <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 9, marginBottom: 85}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <ButtonComponent
                        color='default'
                        onClick={() => HandleChangeEtapa(5)}
                        style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                      Voltar
                      </ButtonComponent>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

                {/* DOAÇÃO ÚNICA */}
                <InvisibleContent visible={doacaoRecorrente === false}>

                  <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 9, marginBottom: 85}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <ButtonComponent
                        color='default'
                        onClick={() => HandleChangeEtapa(8)}
                        style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                      Voltar
                      </ButtonComponent>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

              </Grid>
            </Grid>

            {/* ------------- ETAPA 7 (FORMA DE PAGAMENTO - DOAÇÃO ÚNICA) -----------------------------------------------------------------*/}

            <Grid container style={{ display: etapa === 7 ? 'flex' : 'none' }}>
              <InvisibleContent visible={confValorDoacao === true}>

                <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 75}}>

                  <Grid container item lg={12} sm={12} xs={12} justify="center">
                    <img src={LogoCampanha} style={{ height: 150, width: 210}} />
                  </Grid>

                  <Grid container item lg={12} sm={12} xs={12} style={{marginBottom: 5}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <strong style={{fontSize: '17px', color:'#cd8a4d'}}>
                        Doação única
                      </strong>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                  <Grid container item lg={12} sm={12} xs={12}  style={{marginTop: 10}} >
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <p style={{textAlign: 'justify', fontSize: '14px',}}>
                      Selecione a forma de pagamento da doação:
                      </p>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                  {/* --- VALOR -----*/}

                  <Grid item container justify="center" sm={12} xs={12} style={{paddingTop: 13}}>

                    <Form.Item
                      name="doacao"
                      rules={[
                        { required: false, message: 'Campo obrigatório' },
                      ]}
                    >

                      <Grid item lg={12} sm={12} xs={12} container justify="center" >

                        <Grid item lg={1} sm={1} xs={12} container justify="center"></Grid>

                        <Grid item lg={9} sm={9} xs={12} container justify="center">
                          <Form.Item
                            name="formaDoacao"
                            rules={[
                              { required: true, message: 'Campo obrigatório' },
                            ]}
                          >
                            <Radio.Group>
                              <Grid container justify="center" style={{paddingLeft: 10}} >
                                <Grid justify="center" lg={4} sm={4} xs={4} style={{paddingRight: 95, paddingTop: 5}}>
                                  <Form.Item  name='boleto' valuePropName="checked">
                                    <Radio name='boleto' value="boleto" style={{height: 110, width: 98}} onChange={() => setFormaContribBoleto(true)} onClick={(e: any) => {setFormaContribPix(false); setFormaContribCredito(false); setFormaContribDebito(false); setFormaContribEnergia(false) }} >
                                      <img src={Boleto} style={{ height: 20, width: 25, marginLeft: 10, marginTop: 1}} />
                                      <p> <strong style={{ color: '#a1a1a1',  marginLeft: 8, fontSize: 12, marginTop: 2, textAlign: 'center' }}> Boleto </strong></p>
                                    </Radio>
                                  </Form.Item>
                                </Grid>

                                <Grid justify="center" lg={4} sm={4} xs={4} style={{paddingRight: 60, paddingTop: 5}}>
                                  <Form.Item  name='pix' valuePropName="checked">
                                    <Radio name='pix' value="pix" style={{height: 110, width: 98}} onChange={() => setFormaContribPix(true)} onClick={(e: any) => {setFormaContribCredito(false); setFormaContribBoleto(false); setFormaContribDebito(false); setFormaContribEnergia(false)}} >
                                      <img src={Pix} style={{ height: 18, width: 23, marginLeft: 19, marginTop: 1}} />
                                      <p> <strong style={{ color: '#a1a1a1', marginLeft: 20, fontSize: 13, textAlign: 'center', marginTop: 2}}> PIX </strong></p>
                                    </Radio>
                                  </Form.Item>
                                </Grid>

                                <Grid justify="center" lg={4} sm={4} xs={4} style={{paddingRight: 60, paddingTop: 5}}>
                                  <Form.Item  name='cartao' valuePropName="checked">
                                    <Radio name='cartao' value="cartao" style={{height: 110, width: 98}} onChange={() => setFormaContribCredito(true)} onClick={(e: any) => {setFormaContribPix(false); setFormaContribBoleto(false); setFormaContribDebito(false); setFormaContribEnergia(false) }} >
                                      <img src={Cartao} style={{ height: 15, width: 20, marginRight: 20,  marginTop: 1}} />
                                      <p style={{ color: '#a1a1a1', textAlign: 'center', marginRight: 15, fontSize: 12,  marginTop: 2, lineHeight: 1.1, fontWeight: 600}}> Cartão de crédito </p>
                                    </Radio>
                                  </Form.Item>
                                </Grid>
                              </Grid>
                            </Radio.Group>
                          </Form.Item>
                        </Grid>
                        <Grid justify="center" lg={1} sm={1} xs={1} style={{paddingRight: 1, paddingTop: 5}}></Grid>
                      </Grid>

                      <Grid item lg={12} sm={12} xs={12} container justify="center"></Grid>
                    </Form.Item>
                  </Grid>

                  <InvisibleContent visible={formaContribBoleto === false && formaContribCredito === false && formaContribPix === false}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 43}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitPessoa}
                      >
                        <ButtonComponent
                          color="primary"
                          loading={loadingPessoa}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          //onClick ={() => HandleChangeEtapa(3)}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                    </Grid>
                  </InvisibleContent>

                  <InvisibleContent visible={formaContribBoleto === true || formaContribCredito === true}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 43}}>
                      <ButtonComponent
                        color="primary"
                        //loading={loadingCreatePessoaTeste}
                        type='submit'
                        style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                        onClick={() => {HandleChangeEtapa(8); setConfFormaContrib(true)}}
                      >
                        Continuar
                        <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                      </ButtonComponent>
                    </Grid>
                  </InvisibleContent>

                  <InvisibleContent visible={formaContribPix === true && formaContribBoleto === false && formaContribCredito === false}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 43}}>
                      <ButtonComponent
                        color="primary"
                        //loading={loadingCreatePessoaTeste}
                        type='submit'
                        style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                        onClick={() => {HandleChangeEtapa(8); setConfFormaContrib(true)}}
                      >
                        Continuar
                        <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                      </ButtonComponent>
                    </Grid>
                  </InvisibleContent>


                  <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 9, marginBottom: 85}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <Button
                        color='default'
                        onClick={() => HandleChangeEtapa(3)}
                        style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                      Voltar
                      </Button>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                </Grid>
              </InvisibleContent>
            </Grid>

            {/* ---------------- ETAPA 8 (ENDEREÇO - DOAÇÃO ÚNICA) ------------------------------------------------------------*/}

            <Grid container style={{ display: etapa === 8 ? 'flex' : 'none' }}>
              <InvisibleContent visible={confFormaContrib === true}>
                <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 75}}>

                  <Grid container item lg={12} sm={12} xs={12} justify="center">
                    <img src={LogoCampanha} style={{ height: 150, width: 210}} />
                  </Grid>

                  <Grid container item lg={12} sm={12} xs={12} style={{marginBottom: 5}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <strong style={{fontSize: '17px', color:'#cd8a4d'}}>
                        Doação única
                      </strong>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                  <Grid container item lg={12} sm={12} xs={12}  style={{marginTop: 10}} >
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <p>
                        Preencha com seus dados de endereçamento
                      </p>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>


                  <Grid container justify="center" style={{marginTop: 10}}>
                    <Spin spinning={loadingCEP} tip="Buscando dados do CEP">

                      {/* --- CEP -----*/}
                      <Grid container justify="center">
                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center">
                          <Form.Item
                            name="cep"
                            rules={[
                              { required: true, message: 'Campo obrigatório' }
                            ]}
                          >
                            <MaskedInput
                              mask="11111-111"
                              onChange={(e: any) => {debounceCEP(e.target.value); setFraseDoacao10(true)}}
                              placeholder='CEP'
                            />
                          </Form.Item>
                        </Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                      </Grid>

                      {/* --- UF / CIDADE -----*/}
                      <Grid container justify="center">
                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                        <Grid item lg={2} sm={2} xs={12} justify="center">
                          <Form.Item
                            name="uf"
                            rules={[{ required: true, message: "Campo obrigatório" },
                              {max: 2, message: "Somente sigla"}
                            ]}
                          >
                            <Input
                              onInput={handleInput}
                              onChange={(e: any) => {
                              //onChangeDocumento(e);
                              setFraseDoacao11(true);
                              setUF(e.target.value);
                              }}
                              placeholder='Estado'

                            />
                          </Form.Item>
                        </Grid>

                        <Grid item lg={2} sm={2} xs={12} justify="center">
                          <Form.Item
                            name="cidade"
                            rules={[{ required: true, message: "Campo obrigatório" }]}
                          >
                            <Input
                              onInput={handleInput}
                              onChange={(e: any) =>
                                 setFraseDoacao12(true)
                              }
                              placeholder='Cidade'/>
                          </Form.Item>
                        </Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                      </Grid>

                      {/* --- ENDEREÇO -----*/}
                      <Grid container justify="center">
                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center">
                          <Form.Item
                            name="descricao"
                            rules={[{ required: true, message: "Campo obrigatório" }]}
                          >
                            <Input
                              onInput={handleInput}
                              onChange={(e: any) =>
                               setFraseDoacao13(true)
                              }
                              placeholder='Endereço'/>
                          </Form.Item>
                        </Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                      </Grid>

                      {/* --- Nº / BAIRRO / COMPLEMENTO -----*/}
                      <Grid container justify="center">
                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                        <Grid item lg={1} sm={1} xs={12} justify="center">
                          <Form.Item
                            name="numero"
                            rules={[
                              { required: true, message: 'Campo obrigatório' },
                              {  pattern: /^[0-9]+$/, message: 'Somente números!'},
                            ]}
                          >
                            <Input
                              //onInput={handleInput}
                              onChange={(e: any) =>
                                setFraseDoacao14(true)
                              }
                              placeholder='Número'
                            />
                          </Form.Item>
                        </Grid>

                        <Grid item lg={2} sm={2} xs={12} justify="center">
                          <Form.Item
                            name="bairro"
                            rules={[{required: true, message: "Campo obrigatório" }]}
                          >
                            <Input
                              onInput={handleInput}
                              onChange={(e: any) =>
                                setFraseDoacao15(true)
                              }
                              placeholder='Bairro' />
                          </Form.Item>
                        </Grid>

                        <Grid item lg={1} sm={1} xs={12} justify="center">
                          <Form.Item
                            name="complemento"
                            rules={[{ required: false }]}
                          >
                            <Input
                              onInput={handleInput}
                              ///onChange={(e: any) =>
                               //setFraseDoacao16(true)
                              //}
                              placeholder='Complemento' />
                          </Form.Item>
                        </Grid>

                        <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                      </Grid>
                    </Spin>
                  </Grid>

                  <InvisibleContent visible={fraseDoacao10 === false || fraseDoacao14 === false}>
                    <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 15}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitPessoa}
                      >
                        <ButtonComponent
                          color="primary"
                          loading={loadingPessoa}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          //onClick ={() => HandleChangeEtapa(3)}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                    </Grid>
                  </InvisibleContent>

                  <InvisibleContent visible={fraseDoacao10 === true && fraseDoacao14 === true}>
                    <InvisibleContent visible={formaContribBoleto === true || formaContribPix === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 15}}>
                        <ButtonComponent
                          color="primary"
                          //loading={loadingCreatePessoaTeste}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          onClick={() => {HandleChangeEtapa(10);}}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Grid>
                    </InvisibleContent>
                  </InvisibleContent>

                  <InvisibleContent visible={fraseDoacao10 === true && fraseDoacao14 === true}>
                    <InvisibleContent visible={formaContribCredito === true}>
                      <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 15}}>
                        <ButtonComponent
                          color="primary"
                          //loading={loadingCreatePessoaTeste}
                          type='submit'
                          style={{height: 65, width: 200, fontSize: 16, paddingLeft: 10}}
                          onClick={() => {HandleChangeEtapa(6);}}
                        >
                          Continuar
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Grid>
                    </InvisibleContent>
                  </InvisibleContent>

                  <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 9, marginBottom: 85}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <ButtonComponent
                        color='default'
                        onClick={() => HandleChangeEtapa(7)}
                        style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                      Voltar
                      </ButtonComponent>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>

                </Grid>
              </InvisibleContent>
            </Grid>



            {/* ----------------------------- ETAPA 9 ( ACEITES LGPD - DOAÇÃO RECORRENTE) --------------------*/}

            <InvisibleContent visible={etapa === 9}>
              <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 103}}>

                <Grid container item lg={12} sm={12} xs={12} justify="center">
                  <img src={LogoCampanha} style={{ height: 200, width: 280}} />
                </Grid>

                <Grid container justify="center" style={{marginTop: 10}}>
                  <Grid container justify="center">
                    <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                    {/*<Grid item lg={4} sm={4} xs={12} justify="center">
                      <Form.Item name= "concede_compartilhamento_dados" valuePropName='checked' rules={[{required: true, message: 'Campo obrigatório'}]}>
                        <Checkbox onChange={() => setCheckedTermo1(true)}>
                          <p> Aceito que meus dados pessoais sejam compartilhados para fins de participação nos benefícios do <strong>Clube do Bem</strong>.</p>
                        </Checkbox>
                      </Form.Item>
                            </Grid>*/}

                    <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                  </Grid>
                </Grid>

                <Grid container justify="center" style={{marginTop: 16}}>
                  <Grid container justify="center">
                    <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                    <Grid item lg={4} sm={4} xs={12} justify="center">
                      <Form.Item name= "leu_comunicado_privacidade" valuePropName='checked' rules={[{required: true, message: 'Campo obrigatório'}]}>
                        <Checkbox onChange={() => setCheckedTermo2(true)}>
                          <p><strong> Estou de acordo em fornecer os dados preenchidos </strong>com a finalidade de cumprir os trâmites necessários e receber informações sobre minha doação. <a href="https://www.irmadulce.org.br/politica-de-privacidade" target="_blank" rel="noopener noreferrer" style={{fontSize: 14, color: '#000', textDecorationLine: 'underline'}}>(Política de Privacidade)</a> </p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>

                    <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                  </Grid>
                </Grid>


                {/* ------------- CONCLUIR DOAÇÃO ----------------------------------------------------------- */}

                {/* ------------- BOLETO --------------------------------------------------------------------- */}
                <InvisibleContent visible={formaContribBoleto === true}>

                  <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 25}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitBoleto}
                        //onClick={() => setOpenModalTermo(true)}
                      >
                        <ButtonComponent
                          color="secondary"
                          loading={loadingCreatePessoaBoleto}
                          type='submit'
                          style={{height: 65, width: 250, fontSize: 16, paddingLeft: 10}}


                          //disabled={uf !== 'BA'}
                        >
                          Gerar boleto
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />                        </ButtonComponent>


                      </Form>
                  </Grid>
                </InvisibleContent>

                {/* ------------- PIX --------------------------------------------------------------------- */}
                <InvisibleContent visible={formaContribPix === true}>

                  <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 53}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitPix}
                      >
                        <ButtonComponent
                          color="secondary"
                          loading={loadingCreatePessoaPix}
                          type='submit'
                          style={{height: 65, width: 250, fontSize: 16, paddingLeft: 10}}

                          //disabled={uf !== 'BA'}
                        >
                          Gerar PIX
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                  </Grid>
                </InvisibleContent>

                {/* ------------- CARTÃO DE CRÉDITO --------------------------------------------------------------------- */}
                <InvisibleContent visible={formaContribCredito === true}>

                  <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 53}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitCredito}
                      >
                        <ButtonComponent
                          color="secondary"
                          loading={loadingCreatePessoaCredito}
                          type='submit'
                          style={{height: 65, width: 250, fontSize: 16, paddingLeft: 10}}

                          //disabled={uf !== 'BA'}
                        >
                          Concluir doação
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                  </Grid>
                </InvisibleContent>

                {/* ------------- DÉBITO AUTOMÁTICO --------------------------------------------------------------------- */}
                <InvisibleContent visible={formaContribDebito === true}>

                  <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 53}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitDebito}
                      >
                        <ButtonComponent
                          color="secondary"
                          loading={loadingCreatePessoaDebito}
                          type='submit'
                          style={{height: 65, width: 250, fontSize: 16, paddingLeft: 10}}

                          //disabled={uf !== 'BA'}
                        >
                          Concluir doação
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                  </Grid>
                </InvisibleContent>

                {/* ------------- CONTA DE ENERGIA --------------------------------------------------------------------- */}
                <InvisibleContent visible={formaContribEnergia === true}>

                  <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 53}}>
                    <Form
                      form={formPessoaRef}
                      scrollToFirstError={true}
                      onFinish={onSubmitEnergia}
                    >
                      <ButtonComponent
                        color="secondary"
                        loading={loadingCreatePessoaEnergia}
                        type='submit'
                        style={{height: 65, width: 250, fontSize: 16, paddingLeft: 10}}
                      >
                        Concluir doação
                        <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                      </ButtonComponent>
                    </Form>
                  </Grid>
                </InvisibleContent>

                <InvisibleContent visible={formaContribBoleto === true || formaContribPix === true}>
                  <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 10, marginBottom: 85}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <ButtonComponent
                        color='default'
                        onClick={() => HandleChangeEtapa(5)}
                        style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                      Voltar
                      </ButtonComponent>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

                <InvisibleContent visible={formaContribCredito === true || formaContribDebito === true || formaContribEnergia === true}>
                  <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 10, marginBottom: 85}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <ButtonComponent
                        color='default'
                        onClick={() => HandleChangeEtapa(6)}
                        style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                      Voltar
                      </ButtonComponent>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

              </Grid>
            </InvisibleContent>

            {/* ----------------------------- ETAPA 10 ( ACEITES LGPD - DOAÇÃO ÚNICA) --------------------*/}

            <Grid container style={{ display: etapa === 10 ? 'flex' : 'none' }}>
              <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 103}}>

                <Grid container item lg={12} sm={12} xs={12} justify="center">
                  <img src={LogoCampanha} style={{ height: 200, width: 280}} />
                </Grid>

                <Grid container justify="center" style={{marginTop: 1}}>
                  <Grid container justify="center">
                    <Grid item lg={4} sm={3} xs={12} justify="center" style={{paddingLeft: 100}}></Grid>

                    <Grid item lg={4} sm={6} xs={12} justify="center">
                      <Form.Item name= "leu_comunicado_privacidade" valuePropName='checked' rules={[{required: true, message: 'Campo obrigatório'}]}>
                        <Checkbox onChange={() => setCheckedTermo2(true)}>
                          <p style={{textAlign:'start'}}><strong> Estou de acordo em fornecer os dados preenchidos </strong>com a finalidade de cumprir os trâmites necessários e receber informações sobre minha doação. <a href="https://www.irmadulce.org.br/politica-de-privacidade" target="_blank" rel="noopener noreferrer" style={{fontSize: 14, color: '#000', textDecorationLine: 'underline'}}>(Política de Privacidade)</a> </p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>

                    <Grid item lg={4} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </Grid>

                <Grid container justify="center" style={{marginTop: 10}}>
                  <Grid container justify="center">
                    <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>

                    <Grid item lg={4} sm={4} xs={12} justify="center">

                    </Grid>

                    <Grid item lg={4} sm={4} xs={12} justify="center"></Grid>
                  </Grid>
                </Grid>



                {/* ------------- CONCLUIR DOAÇÃO ----------------------------------------------------------- */}

                {/* ------------- BOLETO --------------------------------------------------------------------- */}
                <InvisibleContent visible={formaContribBoleto === true}>

                  <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 70}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitBoleto}
                      >
                        <ButtonComponent
                          color="primary"
                          loading={loadingCreatePessoaBoleto}
                          type='submit'
                          style={{height: 65, width: 250, fontSize: 16, paddingLeft: 10}}

                          //disabled={uf !== 'BA'}
                        >
                          Gerar boleto
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                  </Grid>
                </InvisibleContent>

                {/* ------------- PIX --------------------------------------------------------------------- */}
                <InvisibleContent visible={formaContribPix === true}>

                  <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 70}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitPix}
                      >
                        <ButtonComponent
                          color="primary"
                          loading={loadingCreatePessoaPix}
                          type='submit'
                          style={{height: 65, width: 250, fontSize: 16, paddingLeft: 10}}

                          //disabled={uf !== 'BA'}
                        >
                          Gerar PIX
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                  </Grid>
                </InvisibleContent>

                {/* ------------- CARTÃO DE CRÉDITO --------------------------------------------------------------------- */}
                <InvisibleContent visible={formaContribCredito === true}>

                  <Grid  container item lg={12} sm={12} xs={12} justify="center" style={{marginTop: 70}}>
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitCredito}
                      >
                        <ButtonComponent
                          color="primary"
                          loading={loadingCreatePessoaCredito}
                          type='submit'
                          style={{height: 65, width: 250, fontSize: 16, paddingLeft: 10}}

                          //disabled={uf !== 'BA'}
                        >
                          Concluir doação
                          <img src={Proximo} style={{ height: 40, width: 40, marginLeft: 10}} />
                        </ButtonComponent>
                      </Form>
                  </Grid>
                </InvisibleContent>


                <InvisibleContent visible={formaContribBoleto === true}>
                  <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 9, marginBottom: 85}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <ButtonComponent
                        color='default'
                        onClick={() => HandleChangeEtapa(8)}
                        style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                      Voltar
                      </ButtonComponent>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

                <InvisibleContent visible={formaContribPix === true}>
                  <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 9, marginBottom: 85}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <ButtonComponent
                        color='default'
                        onClick={() => HandleChangeEtapa(7)}
                        style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                      Voltar
                      </ButtonComponent>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

                <InvisibleContent visible={formaContribCredito === true}>
                  <Grid container item lg={12} sm={12} xs={12} style={{marginTop: 9, marginBottom: 85}}>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center" style={{marginLeft: 50}}></Grid>
                    <Grid  container item lg={5} sm={5} xs={12} justify="center"  style={{marginLeft: 5, marginRight: 5}}>
                      <ButtonComponent
                        color='default'
                        onClick={() => HandleChangeEtapa(6)}
                        style={{color: '#000', fontSize: '14px', textDecorationLine: 'underline', backgroundColor: 'transparent'}}>
                      Voltar
                      </ButtonComponent>
                    </Grid>
                    <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                  </Grid>
                </InvisibleContent>

              </Grid>
            </Grid>

          </Form>
          </InvisibleContent>

          <InvisibleContent visible={openModalTermo === true}>
            <InvisibleContent visible={doacaoRecorrente === true}>
              <AgradecimentoRecorrente />
            </InvisibleContent>

          <InvisibleContent visible={doacaoRecorrente === false}>
            <AgradecimentoUnica />
          </InvisibleContent>

          <InvisibleContent visible={doacaoPaypal === true}>
            <AgradecimentoUnica />
          </InvisibleContent>

          </InvisibleContent>
        </Grid> {/* background */}


          {/* ------------------ RODAPÉ 1 ----------------------- */}

            <FooterInfos/>

          {/* ------------------ RODAPÉ 2 ----------------------- */}

            <FooterSociallis/>

      </Grid>



    </>
  );
};

export default Principal;
